import React, { Component } from 'react'
import "../stylesheets/Footer.css"

export default class Footer extends Component {
    render() {
        return (
            <>
            <div className="footer-container">
                <p className="footer-items"> &copy; Diego Hernandez</p>
            </div>
            </>
        )
    }
}
